import {
  ChatAlt2Icon,
  CloudUploadIcon,
  DesktopComputerIcon,
  ExclamationIcon,
} from '@heroicons/react/outline';
import {
  BookmarkAltIcon,
  BookOpenIcon,
  InformationCircleIcon,
} from '@heroicons/react/solid';
import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from '../../components/Modal';
import PanelCard from '../../components/PanelCard';
import ScoringHistory from '../../components/ScoringHistory';
import { globalBreadcrumbs, globalRole, globalUser } from '../../state';
import { discordInvitationLink } from '../Dashboard';
import Layout from '../Layout';

import { Persistence } from '@hookstate/persistence';
import { useHookstate } from '@hookstate/core';
import ClassService from '../../services/class';
import UploadService from '../../services/upload';

import PageNotFound from '../../components/PageNotFound';

import { SingleDropdown, error, notify } from '@bctc/components';
import LiveSessionService from '../../services/liveSession';
import { ILessonMaterial } from '../../types';
import { Link } from 'react-router-dom';

const speeds = [0.5, 1, 1.25, 1.5, 1.75, 2, 2.5];

const ClassSessionPage: React.FC = () => {
  const [recording, setRecording] = useState('');
  const user = useHookstate(globalUser);
  const role = useHookstate(globalRole);
  role.attach(Persistence('state.role'));
  const isStudent = role.value === 'student';

  const actions = [
    {
      icon: BookOpenIcon,
      key: 'notes',
      name: 'Class Notes',
      href: '#',
      iconForeground: 'text-blue-700',
      iconBackground: 'bg-blue-50',
      description:
        'Take advantage of Future Sphere Course Documentation and read through a beautifully curated lecture notes on the classes that I am taking now.',
    },
    {
      icon: BookmarkAltIcon,
      key: 'homework',
      name: 'Class Homework',
      href: '#',
      iconForeground: 'text-purple-700',
      iconBackground: 'bg-purple-50',
      description: 'View the assignment descriptions for this lesson.',
    },
    {
      icon: () => (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth={1.5}
          stroke='currentColor'
          className='w-6 h-6'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M17.25 6.75 22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3-4.5 16.5'
          />
        </svg>
      ),
      key: 'coding',
      name: 'Coding',
      href: '#',
      iconForeground: 'text-blue-700',
      iconBackground: 'bg-blue-50',
      description: 'Start Coding by using The Future Sphere™ IDE',
    },
    {
      icon: ChatAlt2Icon,
      key: 'discord',
      name: 'Get Homework Help on Discord',
      href: '#',
      iconForeground: 'text-yellow-700',
      iconBackground: 'bg-yellow-50',
      description:
        'Join Future Sphere Discord channel. You can ask questions and get help there.',
    },
    isStudent
      ? {
          icon: CloudUploadIcon,
          key: 'submission',
          name: 'Submit',
          href: '#',
          iconForeground: 'text-blue-700',
          iconBackground: 'bg-blue-50',
          description:
            'Upload your homework or exam here, your teacher will be able to grade your submission.',
        }
      : {
          icon: InformationCircleIcon,
          key: 'manage',
          name: 'Manage',
          href: `#`,
          iconForeground: 'text-blue-700',
          iconBackground: 'bg-blue-50',
          description:
            "Manage this class here, you will be able to grade and see student's homework.",
        },
    {
      icon: DesktopComputerIcon,
      key: 'zoom',
      name: 'Join Zoom Class',
      href: '#',
      iconForeground: 'text-blue-700',
      iconBackground: 'bg-blue-50',
      description: 'Attend your class via zoom.',
    },
  ];
  user.attach(Persistence('state.user'));

  const [speed, setSpeed] = useState(1);
  const classId = window.location.href.split('/')[4];
  const sessionId = window.location.href.split('/')[5];
  const [isSubmissionModalOpen, setSubmissionModalOpen] = useState(false);

  const [attendance, setAttendance] = useState('');
  const [score, setScore] = useState(null);
  const [classInfo, setClassInfo] = useState(null);
  const [previousAttachmentLink, setPreviousAttachmentLink] = useState('');
  const [link, setLink] = useState('');
  const [content, setContent] = useState('');
  const [attachment, setAttachment] = useState(null);
  const [isSubmitted, setSubmitted] = useState(false);
  const [isClassDisabled, setIsClassDisabled] = useState(false);
  const [codaLink, setCodaLink] = useState('');
  const [type, setType] = useState('HOMEWORK');
  const [attachmentPreview, setAttachmentPreview] = useState('Name');
  const userId = user.value.id;
  const [codingFiles, setCodingFiles] = useState([]);
  const [selectCodingFile, setSelectCodingFile] = useState(null);
  const [allLessonMaterials, setAllLessonMaterials] = useState<
    ILessonMaterial[]
  >([]);

  const [isLessonMaterialModalOpen, setLessonMaterialModalOpen] =
    useState(false);
  const [lessonMaterialType, setLessonMaterialType] = useState('');
  const [lessonMaterialTitle, setLessonMaterialTitle] = useState('');

  const history = useHistory();

  useEffect(() => {
    const getClassInfo = async () => {
      if (!classId) {
        throw 'No class id';
      }
      const { data: cohortInfo } = await ClassService.getClassInfoByClassId(
        +classId
      );
      const arr = [];
      cohortInfo.students.map((v) => arr.push(+v.student.profile.studentId));
      if (isStudent && arr.includes(+user.value.id) === false) {
        return history.push('/class');
      }
      // cohortInfo.sessions = cohortInfo.sessions.sort(
      //   (session1, session2) =>
      //     session1.lesson.lessonIndex - session2.lesson.lessonIndex
      // );

      const classSession = cohortInfo.sessions.find((s) => s.id == sessionId);

      if (cohortInfo) {
        const linkResult = classSession.lesson.note;

        if (linkResult) {
          setCodaLink(linkResult);
        }
        setClassInfo(cohortInfo);
        setIsClassDisabled(classSession.isDisabled);

        setRecording(classSession.recording);
        setAllLessonMaterials(classSession.lesson.lessonMaterials);

        if (isStudent) {
          const arr = [];
          for (let i = 0; i < classSession.attendance.length; i++) {
            arr.push(classSession.attendance[i].studentId);
          }

          const getStatus = (v) => v === userId;
          const indexOfUser = arr.findIndex(getStatus);

          if (
            classSession.attendance.length === 0 ||
            !classSession.attendance[indexOfUser] ||
            classSession.attendance[indexOfUser] === undefined
          ) {
            setAttendance('Undefined');
          } else {
            setAttendance(classSession.attendance[indexOfUser].status);
          }

          const array = [];

          for (let i = 0; i < classSession.submissions.length; i++) {
            array.push(classSession.submissions[i].submitterId);
          }

          const getId = (num) => {
            return num === String(user.value.id);
          };

          setScore(
            !classSession.submissions[+array.findIndex(getId)] ||
              classSession.submissions[+array.findIndex(getId)].score === null
              ? 'Not graded or submitted yet'
              : `${classSession.submissions[+array.findIndex(getId)].score}`
          );
        }
      }
      if (!cohortInfo) {
        return;
      }
    };
    getClassInfo();
  }, [window.location.pathname]);

  useEffect(() => {
    globalBreadcrumbs.set([
      { title: 'Home', href: '/class' },
      {
        title: classInfo?.course.title
          ? `${classInfo?.course.title} Cohort ${classInfo?.cohortNumber}`
          : 'Loading...',
        href: classInfo ? `/class/${classId}` : '#',
      },
      {
        title: classInfo ? `session` : 'Loading...',
        href: classInfo ? `/class/${classId}/${sessionId}` : '#',
        isCurrent: true,
      },
    ]);
  }, [classInfo]);

  const getPreviousSubmission = async () => {
    const { data } = await ClassService.getClassInfoByClassId(+classId);

    const submissions = data.students;
    if (isStudent) {
      const previousSubmission = submissions.filter(
        (v) => v.classSessionId == sessionId
      )[0];
      submissions.find(
        (submission) => submission.classSessionId == sessionId
      ) && setSubmitted(true);
      setPreviousAttachmentLink(
        previousSubmission?.attachment ? previousSubmission.attachment : ''
      );
      setContent(previousSubmission?.content ? previousSubmission.content : '');
      setLink(previousSubmission?.link ? previousSubmission.link : '');
    }
  };
  useEffect(() => {
    getPreviousSubmission();
    fetchCodingFiles();
  }, []);
  useEffect(() => {
    const video = document.querySelector('video');

    if (video && video.playbackRate) {
      video.playbackRate = speed;
    }
  }, [speed]);
  const handleActionPanelClick = async (key: string) => {
    switch (key) {
      case 'submission':
        setSubmissionModalOpen(true);
        break;
      case 'zoom':
        window.open(
          classInfo && classInfo.zoomLink,
          '_blank',
          'noopener,noreferrer'
        );
        break;
      case 'homework':
        if (codaLink) {
          window.open(codaLink, '_blank', 'noopener,noreferrer');
        } else {
          setLessonMaterialModalOpen(true);
          setLessonMaterialType('HOMEWORK');
          setLessonMaterialTitle('Class Homework');
        }
        break;
      case 'notes':
        if (codaLink) {
          window.open(codaLink, '_blank', 'noopener,noreferrer');
        } else {
          setLessonMaterialModalOpen(true);
          setLessonMaterialType('NOTE');
          setLessonMaterialTitle('Class Notes');
        }
        break;
      case 'coding': {
        const { data: existCodingFile } =
          await LiveSessionService.getCodingFileByClassSessionId({
            classSessionId: sessionId,
          });
        if (existCodingFile) {
          return history.push(`/live/${existCodingFile.id}`);
        }
        const { data } = await LiveSessionService.createNewFile({
          filename:
            classInfo.course.title +
            ' ' +
            classInfo.sessions.find((s) => s.id === sessionId).lesson.title,
          ownerId: user.value.profile.id,
          classSessionId: sessionId,
        });
        if (data) {
          history.push(`/live/${data.id}`);
        } else {
          error('Something went wrong while creating a coding file');
        }
        break;
      }

      case 'discord':
        window.open(discordInvitationLink);
        break;
      case 'manage':
        history.push(`/class/${classId}/${sessionId}/manage`);
        break;
      default:
        break;
    }
  };

  const handleFileChange = async (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];

    setAttachmentPreview(file.name as string);
    let data = '';

    reader.onloadend = async () => {
      const convert1Base64 = (reader.result as string).replace('data:', '');
      const convert2Base64 = convert1Base64.replace(/^.+,/, '');
      data = convert2Base64;
      const filename = String(file.name);
      const result = await UploadService.createLinkForFile(filename, data);

      setAttachment(result);
    };
    reader.readAsDataURL(file);
  };
  const handleFileDelete = () => {
    setAttachment(null);
  };

  const handleCreateSubmission = async () => {
    let attachmentUrl = '';
    if (attachment) {
      const formData = new FormData();
      formData.append('data', attachment.data);
    } else {
      attachmentUrl = previousAttachmentLink;
    }
    if (link == '' && selectCodingFile == '') {
      error('Must provide a link or choose a file to submit.');
      return;
    } else {
      const student = `${user.value.id}`;

      const classSession = sessionId;

      const result = await UploadService.handleUpsertSubmission({
        attachment: attachment ? attachment.data.Location : null,
        content,
        link,
        student,
        type,
        classSession,
        codingFile: selectCodingFile,
        score: null,
      });

      if (result) {
        getPreviousSubmission();
        notify({
          description: 'Homework successfully submitted!',
        });
        setSubmissionModalOpen(false);
      } else {
        error('Something went wrong while submitting');
        return;
      }
    }
  };

  const fetchCodingFiles = useCallback(async () => {
    const { data } = await LiveSessionService.getFilesByUserId({
      selectedOption: '',
      userId: user.value.profile.id,
      searchQuery: '',
    });
    setCodingFiles(data.data);
  }, []);

  return isClassDisabled ? (
    <PageNotFound />
  ) : (
    <Layout bg>
      <div className='max-w-3xl px-4 mx-auto sm:px-6 lg:max-w-7xl lg:px-8'>
        {/* Main 3 column grid */}
        <div className='grid items-start grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-8'>
          {/* Left column */}
          <div
            className={`grid grid-cols-1 gap-4 ${
              role.value == 'teacher' ? 'lg:col-span-3' : 'lg:col-span-2'
            }`}
          >
            <PanelCard
              divider
              headerText={`${
                classInfo &&
                classInfo.sessions.find((s) => s.id === sessionId).lesson.title
              }`}
              headerRight={
                <div
                  onClick={() => {
                    isStudent
                      ? setSubmissionModalOpen(true)
                      : history.push(`/class/${classId}/${sessionId}/manage`);
                  }}
                  className='inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md shadow-sm cursor-pointer hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                >
                  {isStudent ? 'Submit' : 'Manage'}
                </div>
              }
              footer={
                recording && (
                  <div className='flex items-center justify-between px-4 py-5 sm:px-6'>
                    <p className='mr-2 font-medium'>Playback Speed:</p>
                    <span className='relative z-0 inline-flex rounded-md shadow-sm'>
                      <div>
                        {speeds.map((v, i) => (
                          <button
                            key={i}
                            type='button'
                            onClick={() => setSpeed(v)}
                            className={classNames(
                              'relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-200 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 rounded-none',
                              {
                                'rounded-l-md': i === 0,
                                '-ml-px': i > 0,
                                'rounded-r-md': i === speeds.length - 1,
                                'bg-gray-200': v === speed,
                              }
                            )}
                          >
                            {v}x
                          </button>
                        ))}
                      </div>
                    </span>
                  </div>
                )
              }
            >
              {recording ? (
                <video
                  src={
                    recording.replace('http:', 'https:').includes('https')
                      ? recording
                      : 'https://' + recording
                  }
                  controls
                />
              ) : (
                <div className='p-4 rounded-none bg-yellow-50'>
                  <div className='flex'>
                    <div className='flex-shrink-0'>
                      <ExclamationIcon
                        className='w-5 h-5 text-yellow-400'
                        aria-hidden='true'
                      />
                    </div>
                    <div className='ml-3'>
                      <h3 className='text-sm font-medium text-yellow-800'>
                        Video has not been uploaded yet or is still being
                        processed
                      </h3>
                      <div className='mt-2 text-sm text-yellow-700'>
                        <p>
                          Please be patient, if video is supposed to be here, it
                          might be processing, feel free to contact your
                          teacher.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </PanelCard>
            {/* Actions panel */}
            <section aria-labelledby='quick-links-title'>
              <div className='overflow-hidden bg-gray-200 divide-y divide-gray-200 rounded-lg shadow sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px'>
                <h2 className='sr-only' id='quick-links-title'>
                  Quick links
                </h2>
                {actions.map((action, actionIdx) => (
                  <div
                    key={action.name}
                    className={classNames(
                      actionIdx === 0
                        ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none'
                        : '',
                      actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
                      actionIdx === actions.length - 2
                        ? 'sm:rounded-bl-lg'
                        : '',
                      actionIdx === actions.length - 1
                        ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none'
                        : '',
                      action.key === 'tutoring' ? 'opacity-75' : '',
                      'relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-cyan-500'
                    )}
                  >
                    <div>
                      <span
                        className={classNames(
                          action.iconBackground,
                          action.iconForeground,
                          'rounded-lg inline-flex p-3 ring-4 ring-white'
                        )}
                      >
                        <action.icon className='w-6 h-6' aria-hidden='true' />
                      </span>
                    </div>
                    <div className='mt-8'>
                      <h3 className='text-lg font-medium'>
                        <button
                          disabled={
                            action.key === 'tutoring'
                            // || (action.key === 'notes' && !codaLink) ||
                            // (action.key === 'homework' && !codaLink)
                          }
                          className={`focus:outline-none`}
                          onClick={() => handleActionPanelClick(action.key)}
                        >
                          {/* Extend touch target to entire panel */}
                          <span
                            className='absolute inset-0'
                            aria-hidden='true'
                          />
                          {action.name}
                        </button>
                      </h3>
                      <p className='mt-2 text-sm text-gray-500'>
                        {action.description}
                      </p>
                    </div>
                    <span
                      className={classNames(
                        'pointer-events-none absolute top-6 right-6 text-gray-300',
                        action.key != 'tutoring'
                          ? 'group-hover:text-gray-400'
                          : ''
                      )}
                      aria-hidden='true'
                    >
                      <svg
                        className='w-6 h-6'
                        xmlns='http://www.w3.org/2000/svg'
                        fill='currentColor'
                        viewBox='0 0 24 24'
                      >
                        <path d='M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z' />
                      </svg>
                    </span>
                  </div>
                ))}
              </div>
            </section>
          </div>
          <div className='space-y-4'>
            {isStudent ? (
              <dl className='grid grid-cols-1 gap-5 sm:grid-cols-2'>
                <div className='col-span-2 px-4 py-5 overflow-hidden bg-white rounded-lg shadow xl:col-span-1 sm:p-6'>
                  <dt className='text-sm font-medium text-gray-500 truncate'>
                    Attendance Status
                  </dt>
                  <dd
                    className={classNames(
                      'mt-1 text-3xl font-semibold text-gray-900',
                      {
                        //Present, late, absent, excused
                        'text-green-500': attendance === 'PRESENT',
                        'text-yellow-500': attendance === 'LATE',
                        'text-red-500': attendance === 'ABSENT',
                        'text-blue-500': attendance === 'EXCUSED',
                      }
                    )}
                  >
                    {attendance === 'PRESENT' && 'Present'}
                    {attendance === 'LATE' && 'Late'}
                    {attendance === 'ABSENT' && 'Absent'}
                    {attendance === 'EXCUSED' && 'Excused'}
                    {attendance === 'Undefined' && '- - -'}
                    {!attendance && '- - -'}
                  </dd>
                </div>
                <div className='col-span-2 px-4 py-5 overflow-hidden bg-white rounded-lg shadow xl:col-span-1 sm:p-6'>
                  <dt className='text-sm font-medium text-gray-500 truncate'>
                    Homework Grade
                  </dt>

                  <dd
                    className={classNames(
                      'mt-1 text-2xl font-semibold text-gray-900',
                      {
                        'text-green-500': +score >= 85,
                        'text-yellow-500': 85 >= +score && +score >= 65,
                        'text-red-500': 65 > +score && +score,
                        'text-3xl': +score || +score == 0,
                        'text-xl': !isSubmitted,
                      }
                    )}
                  >
                    {score
                      ? `${score}`
                      : isSubmitted
                      ? 'Submitted'
                      : 'Not submitted'}
                  </dd>
                </div>
              </dl>
            ) : null}

            {role.value == 'teacher' ? null : (
              <PanelCard headerText='Scoring History' padding={false}>
                <div className='p-4 pt-0'>
                  <ScoringHistory
                    classId={+classId}
                    userId={`${userId}`}
                    classSessionId={sessionId}
                  />
                </div>
              </PanelCard>
            )}
          </div>
        </div>
      </div>
      <Modal
        open={isSubmissionModalOpen}
        close={() => setSubmissionModalOpen(false)}
        title='Submit Submission'
        buttonText='Submit'
        onSubmit={() => {
          handleCreateSubmission();
        }}
      >
        <p className='text-sm text-gray-500'>
          You can choose the file from The Future Sphere™ online file system, a
          repl link or a file from your computer.
        </p>
        <div className='mt-4'>
          <SingleDropdown
            label='Select a file from your coding files'
            data={
              codingFiles && codingFiles.length > 0
                ? codingFiles.map((file) => {
                    return {
                      title: file.filename,
                      id: file.id,
                    };
                  })
                : []
            }
            value={selectCodingFile ? selectCodingFile : ''}
            onChange={(file) => {
              setSelectCodingFile(file);
            }}
          />
        </div>
        {/* add a or split line */}
        <div className='flex items-center justify-center mt-6'>
          <div className='w-1/2 border-t border-gray-300' />
          <p className='px-2 text-sm text-gray-500'>or</p>
          <div className='w-1/2 border-t border-gray-300' />
        </div>
        <div className='grid grid-cols-1 mt-6 gap-y-6 gap-x-4 sm:grid-cols-6'>
          <div className='sm:col-span-6'>
            <label
              htmlFor='username'
              className='block text-sm font-medium text-left text-gray-700'
            >
              Link to Submission
            </label>

            <div className='flex mt-1 rounded-md shadow-sm'>
              <input
                onChange={(e) => setLink(e.target.value)}
                type='text'
                name='url'
                id='url'
                defaultValue={link}
                autoComplete='url'
                placeholder='Repl, Scratch, Website URL or Github Repo'
                className='flex-1 block w-full min-w-0 border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 sm:text-sm'
              />
            </div>
          </div>

          <div className='inline-flex  gap-4'>
            <div className='text-sm text-gray-700 font-medium py-2'>Type:</div>
            <div
              onClick={() => setType('EXAM')}
              className={`${
                type === 'EXAM'
                  ? 'bg-blue-600 text-white rounded-full hover:bg-blue-600'
                  : null
              } px-4 py-2 text-sm font-medium border-blue-200 bg-white  border rounded-md shadow-sm cursor-pointer hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
            >
              Exam
            </div>
            <div
              onClick={() => setType('HOMEWORK')}
              className={`${
                type === 'HOMEWORK'
                  ? 'bg-blue-600 text-white rounded-full hover:bg-blue-600'
                  : null
              } px-4 py-2 text-sm font-medium  bg-white  border border-blue-200 rounded-md shadow-sm cursor-pointer hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
            >
              Homework
            </div>
          </div>

          <div className='sm:col-span-6'>
            <div className='flex justify-between'>
              <label
                htmlFor='cover-photo'
                className='block text-sm font-medium text-left text-gray-700'
              >
                Additional comments
              </label>
              <span className='text-sm text-gray-500' id='email-optional'>
                Optional
              </span>
            </div>

            <div className='mt-1'>
              <textarea
                id='about'
                name='about'
                rows={3}
                defaultValue={content}
                className='block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm'
                onChange={(e) => setContent(e.target.value)}
              />
            </div>
            <p className='mt-2 text-sm text-left text-gray-500'>
              You can optionally write something about your homework.
            </p>
          </div>

          <div className='sm:col-span-6'>
            <div className='flex justify-between'>
              <label
                htmlFor='cover-photo'
                className='block text-sm font-medium text-left text-gray-700'
              >
                Submission file
              </label>
              <span className='text-sm text-gray-500' id='email-optional'>
                Optional
              </span>
            </div>
            {previousAttachmentLink && (
              <a
                href={previousAttachmentLink}
                className='text-sm font-medium text-left text-blue-700'
              >
                Previous submission file
              </a>
            )}
            {attachment ? (
              <div className='flex items-center mt-1 border-2 border-gray-300 border-solid rounded-md'>
                <span className='rounded-md py-0.5 pl-2.5 pr-1 text-sm font-medium bg-blue-100 text-blue-700 m-1 w-full flex justify-between'>
                  <div className='flex flex-col'>
                    <span>{attachmentPreview || ''}</span>
                  </div>
                  <button
                    type='button'
                    className='w-4 h-4 my-auto mr-1 text-blue-400 rounded-full hover:bg-blue-200 hover:text-blue-500 focus:outline-none focus:bg-blue-300 focus:text-blue-500'
                    onClick={handleFileDelete}
                  >
                    <span className='sr-only'>Remove large option</span>
                    <svg
                      className='w-2 h-2 m-auto'
                      stroke='currentColor'
                      fill='none'
                      viewBox='0 0 8 8'
                    >
                      <path
                        strokeLinecap='round'
                        strokeWidth='1.5'
                        d='M1 1l6 6m0-6L1 7'
                      />
                    </svg>
                  </button>
                </span>
              </div>
            ) : (
              <div className='flex justify-center px-6 pt-5 pb-6 mt-1 border-2 border-gray-300 border-dashed rounded-md'>
                <div className='space-y-1 text-center'>
                  <svg
                    className='w-12 h-12 mx-auto text-gray-400'
                    stroke='currentColor'
                    fill='none'
                    viewBox='0 0 48 48'
                    aria-hidden='true'
                  >
                    <path
                      d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
                      strokeWidth={2}
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                  <div className='flex text-sm text-gray-600'>
                    <label
                      htmlFor='file-upload'
                      className='relative font-medium text-blue-600 bg-white rounded-md cursor-pointer hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500'
                    >
                      <span>Upload a file</span>
                      <input
                        id='file-upload'
                        name='file-upload'
                        type='file'
                        className='sr-only'
                        onChange={(e) => handleFileChange(e)}
                        accept='image/jpg, image/png, image/jpeg, /image/gif, .py, .txt, .java'
                      />
                    </label>
                    <p className='pl-1'>or drag and drop</p>
                  </div>
                  <p className='text-xs text-gray-500'>
                    Accepted file types: png, jpg, jpeg, py
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
      <Modal
        open={isLessonMaterialModalOpen}
        close={() => setLessonMaterialModalOpen(false)}
      >
        <div className=''>
          <div className='flex justify-center mt-3 mb-6'>
            <div className='text-xl px-2 py-2 font-medium'>
              {lessonMaterialTitle}
            </div>
          </div>
          {allLessonMaterials.filter(
            (lesson) => String(lesson.type) === lessonMaterialType
          ).length > 0 ? (
            allLessonMaterials
              .filter((lesson) => String(lesson.type) === lessonMaterialType)
              .map((lesson, index) => (
                <div key={lesson.id} className='mb-4 flex justify-between'>
                  <div>
                    {index + 1}.&nbsp;
                    <Link
                      to={`/lessonMaterials/${lesson.id}`}
                      className='text-blue-500 hover:text-blue-700'
                    >
                      {lesson.title}
                    </Link>
                  </div>
                  <div className='bg-gray-100 rounded-md px-3 text-gray-500'>
                    {lesson.type}
                  </div>
                </div>
              ))
          ) : (
            <div className='flex justify-center'>
              <p className=' px-3 rounded-md text-red-500'>
                No lessons available.
              </p>
            </div>
          )}
        </div>
      </Modal>
    </Layout>
  );
};

export default ClassSessionPage;
