import React, { useEffect } from 'react';
import { useAuth } from 'oidc-react';
import { clearToken } from '../../services/config';
import { useHookstate } from '@hookstate/core';
import { globalAuthToken } from '../../state';
import { logoUrl } from '../../constants/assets';

interface Props {}
const LogoutComponent: React.FC<Props> = () => {
  const authToken = useHookstate(globalAuthToken);

  const auth = useAuth();
  useEffect(() => {
    authToken.set(null);
    clearToken();
    auth.signOut().then(() => {
      window.location.replace('/login');
    });
  }, []);
  return (
    <div className='flex flex-col items-center justify-center min-h-screen bg-gray-100'>
      <div className='bg-white p-6 rounded-lg shadow-lg text-center'>
        <img src={logoUrl} alt='Logging out' className='h-24 mx-auto mb-4' />
        <h1 className='text-2xl font-semibold mb-2'>Logging you out...</h1>
        <p className='text-gray-600'>
          Please wait a moment while we log you out.
        </p>
      </div>
    </div>
  );
};

export default LogoutComponent;
