import { notify, wordDate } from '@bctc/components';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import React, { useEffect, useMemo, useState } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';
import Empty from '../../components/Empty';
import PanelCard from '../../components/PanelCard';
import ScoringHistory from '../../components/ScoringHistory';
import TemplateFeedback from '../../components/TemplateFeedback';
import {
  emptyClassSession,
  emptyCohort,
  emptyCourse,
  emptyLesson,
  emptyProfile,
  emptyTeacher,
} from '../../constants/emptyModel';
import { getSessionBySessionId } from '../../services/class';
import { giveFeedback, giveScore } from '../../services/submission';
import { globalBreadcrumbs } from '../../state';
import { ClassSessionWithDetails } from '../../types';
import Layout from '../Layout';
import { feedbackTemplates, scoringStandards } from './constants';
import { CodingEditor } from '../../components/CodingEditor';
import Terminal from '../../components/Terminal';
import { useLoadScript } from '../../helpers/loadLib';
declare global {
  interface Window {
    Sk: any;
  }
}
interface Props {}

const GradingPage: React.FC<Props> = () => {
  const history = useHistory();
  const split = window.location.pathname.split('/');
  const classId = +split[2];
  const sessionId = split[3];
  const studentId = split[5];
  const [isScriptLoaded, setScriptLoaded] = useState(false);
  const [runningCode, setRunningCode] = useState<boolean>(false);
  const [updateGrading, setUpdateGrading] = useState(false);
  const [classSession, setClassSession] = useState<ClassSessionWithDetails>({
    ...emptyClassSession,
    attendance: [],
    comments: [],
    lesson: emptyLesson,
    submissions: [],
    cohort: {
      ...emptyCohort,
      course: emptyCourse,
      students: [],
      instructor: {
        ...emptyTeacher,
        profile: emptyProfile,
        cohorts: [],
        storeCreditTransactionsManaged: [],
        payment: [],
        announcements: [],
      },
      sessions: [],
      frequency: [],
    },
  });
  useEffect(() => {
    const loadScripts = async () => {
      try {
        await useLoadScript('/js/skulpt.min.js');
        await useLoadScript('/js/skulpt-stdlib.js');
        setScriptLoaded(true);
      } catch (error) {
        error({
          title: 'Failed to load skulpt',
        });
      }
    };

    loadScripts();
  }, []);
  const student = useMemo(
    () =>
      classSession.cohort.students.find(
        (enrollment) => enrollment.studentId === studentId
      )?.student,
    [classSession, studentId]
  );
  const submissions = useMemo(
    () =>
      classSession.submissions
        .filter(({ submitterId }) => submitterId === studentId)
        .sort(
          (a, b) =>
            new Date(b.submittedAt).getTime() -
            new Date(a.submittedAt).getTime()
        ),
    [classSession, studentId]
  );
  const gradedLength = useMemo(() => {
    const gradedMap = {};
    classSession.submissions.forEach(({ submitterId, score }) => {
      if (score) gradedMap[submitterId] = true;
    });
    return Object.keys(gradedMap).length;
  }, [classSession]);
  const studentsWithSubmissions = useMemo(
    () =>
      classSession.cohort.students.filter(({ studentId }) =>
        classSession.submissions.find(
          ({ submitterId }) => submitterId === studentId
        )
      ),
    [classSession]
  );
  const studentIndex = useMemo(
    () =>
      studentsWithSubmissions.findIndex(
        (enrollment) => enrollment.studentId === studentId
      ),
    [studentId, classSession]
  );
  const [loading, setLoading] = useState(true);
  const [feedback, setFeedback] = useState('');
  const [terminalTab, setTerminalTab] = useState<'graph' | 'output'>('graph');
  const [showOutputDot, setShowOutputDot] = useState<boolean>(false);
  const [terminalContent, setTerminalContent] = useState<string>('');
  const handleRunPythonCode = async (codeToRun: string) => {
    if (!codeToRun) return notify({ title: 'Please enter some code to run' });
    setRunningCode(true);
    if (window.Sk === undefined) return;
    window.Sk.configure({
      output: (text: string) => {
        if (terminalTab !== 'output') {
          setShowOutputDot(true);
        }
        setTerminalContent(text);
      },
    });

    (window.Sk.TurtleGraphics || (window.Sk.TurtleGraphics = {})).target =
      'turtle-canvas';

    window.Sk.misceval
      .asyncToPromise(() => {
        return window.Sk.importMainWithBody('<stdin>', false, codeToRun, true);
      })
      .then(
        () => notify({ title: 'Code ran successfully' }),
        (err: any) => {
          setTerminalContent('ERR: ' + err.toString());
          if (terminalTab !== 'output') {
            setShowOutputDot(true);
          }
        }
      );
    setRunningCode(false);
  };
  const fetchSession = async () => {
    setLoading(true);
    if (!sessionId) return;
    const { data: result } = await getSessionBySessionId(sessionId);
    if (!result) return;
    setClassSession(result);
    setLoading(false);
  };

  const handleSubmitScore = async (score: number) => {
    const { data: result } = await giveScore(submissions[0].id, score);
    if (!result) return;
    fetchSession();
    setUpdateGrading(!updateGrading);
  };

  const submitFeedback = async () => {
    const { data: result } = await giveFeedback(submissions[0].id, feedback);
    if (!result) return;
    fetchSession();
    setUpdateGrading(!updateGrading);
  };

  useEffect(() => {
    globalBreadcrumbs.set([
      { title: 'Home', href: '/class' },
      {
        title: loading
          ? 'Loading...'
          : `${classSession.cohort.course.title} Cohort ${classSession.cohort.cohortNumber}`,
        href: loading ? '#' : `/class/${classId}`,
      },
      {
        title: loading
          ? 'Loading...'
          : `Class ${classSession.lesson.lessonIndex}`,
        href: loading ? '#' : `/class/${classId}/${sessionId}`,
      },
      {
        title: `Manage`,
        href: loading ? '#' : `/class/${classId}/${sessionId}/manage`,
      },
      {
        title: 'Grading',
        href: window.location.pathname,
        isCurrent: true,
      },
    ]);
  }, [window.location.pathname, loading]);

  useEffect(() => {
    fetchSession();
  }, [window.location.pathname, sessionId]);

  return (
    <Layout bg>
      <div className='max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8'>
        {!loading &&
        !classSession.submissions.find(
          ({ submitterId }) => submitterId === studentId
        ) ? (
          <div className='max-w-lg mx-auto bg-white rounded-md mt-4 p-10 lg:max-w-5xl'>
            <h2 className='text-lg font-medium text-gray-900'>
              Can not find this submission
            </h2>
            <p className='mt-1 text-sm text-gray-500'>
              Please make sure that the submission for this id has not been
              deleted.
            </p>
            <div className='mt-6 flex'>
              <a
                href='#'
                onClick={() => history.goBack()}
                className='text-sm font-medium text-blue-600 hover:text-blue-500'
              >
                Go back
                <span aria-hidden='true'> &rarr;</span>
              </a>
            </div>
          </div>
        ) : (
          <div className='grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8'>
            {/* Left column */}
            <div className='grid grid-cols-1 gap-4 lg:col-span-2'>
              {loading ? (
                <SkeletonTheme>
                  <Skeleton count={1} height={300} />
                </SkeletonTheme>
              ) : (
                <PanelCard
                  divider
                  headerText={`Submission by ${student.profile.firstName} ${
                    student.profile.lastName || ''
                  }`}
                  headerSubtitle={`Submitted on ${wordDate(
                    submissions[0].submittedAt
                  )}`}
                  headerRight={
                    <span className='relative z-0 inline-flex rounded-md items-center mt-4 md:mt-0'>
                      <span className='text-sm mr-6'>
                        {gradedLength}/{studentsWithSubmissions.length} Graded
                      </span>
                      <button
                        title={
                          studentIndex === 0
                            ? 'This is the first submission'
                            : 'Go back'
                        }
                        type='button'
                        onClick={() =>
                          history.push(
                            `/class/${classId}/${sessionId}/submission/${
                              studentsWithSubmissions[studentIndex - 1]
                                .studentId
                            }`
                          )
                        }
                        disabled={studentIndex === 0}
                        className={`relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 focus:z-10 focus:outline-none hover:bg-gray-50 ${
                          studentIndex === 0
                            ? 'bg-gray-100 cursor-not-allowed'
                            : 'hover:bg-gray-50'
                        }`}
                      >
                        <span className='sr-only'>Previous</span>
                        <ChevronLeftIcon
                          className='h-4 w-4'
                          aria-hidden='true'
                        />
                      </button>

                      <button
                        title={
                          studentIndex === studentsWithSubmissions.length - 1
                            ? 'This is the last submission'
                            : 'Go to the next one'
                        }
                        disabled={
                          studentIndex === studentsWithSubmissions.length - 1
                        }
                        type='button'
                        onClick={() =>
                          history.push(
                            `/class/${classId}/${sessionId}/submission/${
                              studentsWithSubmissions[studentIndex + 1]
                                .studentId
                            }`
                          )
                        }
                        className={`-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 focus:z-10 ${
                          studentIndex === studentsWithSubmissions.length - 1
                            ? 'bg-gray-100 cursor-not-allowed'
                            : 'hover:bg-gray-50'
                        }`}
                      >
                        <span className='sr-only'>Next</span>
                        <ChevronRightIcon
                          className='h-4 w-4'
                          aria-hidden='true'
                        />
                      </button>
                    </span>
                  }
                >
                  {submissions[0] ? (
                    <div className='px-2'>
                      {submissions[0].codingFile && isScriptLoaded && (
                        <div className='h-[500px] w-full flex bg-gray-100 pt-2 border border-gray-400 overflow-hidden rounded-md mb-4'>
                          <div className='flex-1'>
                            <CodingEditor
                              value={submissions[0].codingFile.content}
                              runningCode={runningCode}
                              handleRunCode={(code) =>
                                handleRunPythonCode(code)
                              }
                              editable={false}
                              language={'python'}
                            />
                          </div>
                          <div className='flex-1'>
                            <Terminal
                              terminalTab={terminalTab}
                              setTerminalTab={setTerminalTab}
                              terminalContent={terminalContent}
                              showOutputDot={showOutputDot}
                              setShowOutputDot={setShowOutputDot}
                            />
                          </div>
                        </div>
                      )}
                      {submissions[0].link ? (
                        <>
                          {submissions[0].link.includes(
                            'https://replit.com/'
                          ) ? (
                            <iframe
                              className='mb-2'
                              frameBorder='0'
                              width='100%'
                              height='900px'
                              src={
                                submissions[0].link.split('#')[0] + '?lite=true'
                              }
                            ></iframe>
                          ) : null}
                        </>
                      ) : submissions[0].content ? (
                        <>
                          {submissions[0].content.includes(
                            'https://replit.com/'
                          ) ? (
                            <iframe
                              frameBorder='0'
                              width='100%'
                              height='600px'
                              className='mb-3'
                              src={
                                submissions[0].content.split('#')[0] +
                                '?lite=true'
                              }
                            ></iframe>
                          ) : null}
                        </>
                      ) : null}
                      <p className='truncate'>
                        <span className='text-gray-500'>Link: </span>
                        {submissions[0].link ? (
                          submissions[0].link.includes('http') ? (
                            <a
                              href={submissions[0].link}
                              target='_blank'
                              rel='noreferrer'
                              className=' hover:text-gray-700'
                              title={submissions[0].link}
                            >
                              {submissions[0].link}
                            </a>
                          ) : (
                            submissions[0].link
                          )
                        ) : (
                          'None'
                        )}
                      </p>
                      <p>
                        <span className='text-gray-500'>
                          Additional comment:{' '}
                        </span>
                        {submissions[0].content ? (
                          submissions[0].content.includes('http') ? (
                            <a
                              href={submissions[0].content}
                              target='_blank'
                              rel='noreferrer'
                              className='text-gray-500 hover:text-gray-700'
                              title={submissions[0].content}
                            >
                              {submissions[0].content}
                            </a>
                          ) : (
                            submissions[0].content
                          )
                        ) : (
                          'None'
                        )}
                      </p>
                      <p>
                        <span className='text-gray-500'>Attachment: </span>
                        {submissions[0].attachment ? (
                          <button
                            onClick={() =>
                              window.open(submissions[0].attachment)
                            }
                            type='button'
                            className='bg-white text-sm font-medium text-blue-500 hover:text-blue-600 focus:outline-none'
                          >
                            Download
                          </button>
                        ) : (
                          'None'
                        )}
                      </p>
                    </div>
                  ) : (
                    <Empty
                      message='This student not submit submission yet.'
                      pl={2}
                      pr={2}
                      pt={0}
                      pb={0}
                    />
                  )}
                </PanelCard>
              )}
              <PanelCard headerText='Grading History' divider>
                <ScoringHistory
                  classId={classId}
                  userId={studentId}
                  classSessionId={classSession.id}
                  isUpdated={updateGrading}
                />
              </PanelCard>
            </div>
            <div className='grid grid-cols-1 gap-4 lg:col-span-1'>
              <PanelCard headerText='Scoring' divider>
                <div className='space-y-4 px-2'>
                  {scoringStandards.map((v, i) => (
                    <div
                      className='flex items-start cursor-pointer'
                      key={i}
                      onClick={() => handleSubmitScore(v.score)}
                    >
                      <input
                        disabled={loading}
                        id='scoring'
                        name='scoring'
                        type='radio'
                        checked={
                          v.score === submissions.find((v) => v.score)?.score
                        }
                        className='focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 mt-0.5 outline-none cursor-pointer'
                      />
                      <div className='ml-3 text-sm cursor-pointer'>
                        <label className='font-medium text-blue-700 cursor-pointer'>
                          {v.score}%
                        </label>
                        <p className='text-gray-500'>{v.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </PanelCard>
              <PanelCard
                headerText='Feedback'
                divider
                padding={false}
                footer={
                  <div className='sm:px-6 p-4 flex items-center justify-end'>
                    <button
                      onClick={submitFeedback}
                      disabled={loading}
                      type='button'
                      className='inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                    >
                      Submit
                    </button>
                  </div>
                }
              >
                <div className='px-4 pt-4 pb-1'>
                  <h3 className='font-medium text-gray-900 mb-2 px-2'>
                    New Feedback
                  </h3>
                  <div className='px-2'>
                    <textarea
                      id='about'
                      name='about'
                      rows={3}
                      className='py-3 mt-2 shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-md'
                      onChange={(e) => setFeedback(e.target.value)}
                      value={feedback}
                    />
                  </div>

                  <p className='mt-2 text-sm text-gray-500 px-2'>
                    Write a feedback for this student&apos;s submission.
                  </p>
                  <h3 className='font-medium text-gray-900 mb-2 mt-5 px-2'>
                    Templates
                  </h3>
                  <ul
                    role='list'
                    className='mt-2 border-t border-gray-200 divide-y divide-gray-200 px-2'
                  >
                    {submissions.length &&
                      feedbackTemplates.map((v, i) => {
                        const formatFeedback = v.content.replace(
                          '{name}',
                          `${student.profile.firstName} ${
                            student.profile.lastName || ''
                          }`
                        );
                        return (
                          <TemplateFeedback
                            formatFeedback={formatFeedback}
                            setFeedback={setFeedback}
                            v={v}
                            key={i}
                          />
                        );
                      })}
                  </ul>
                </div>
              </PanelCard>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default GradingPage;
