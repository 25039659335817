import {
  ChatAltIcon,
  ClipboardCheckIcon,
  ClipboardIcon,
} from '@heroicons/react/outline';
import React, { useState } from 'react';

import { useHookstate } from '@hookstate/core';

import { globalRole } from '../../state';
import { Persistence } from '@hookstate/persistence';
import ClassService from '../../services/class';
import dayjs from 'dayjs';
import a from '../../services/config';
import { StudentWithProfile } from '../../types';
import { emptyProfile, emptyStudent } from '../../constants/emptyModel';
import { wordDate } from '@bctc/components';
import { Feedback, Submission } from '@prisma/client';

interface Props {
  classId: number;
  userId: string;
  isUpdated?: boolean;
  classSessionId: string;
}
interface TimeLine {
  createdAt: Date;
  type: 'SUBMISSION' | 'FEEDBACK' | 'SCORE';
  content?: string;
}

const ScoringHistory: React.FC<Props> = ({
  classId,
  userId,
  isUpdated,
  classSessionId,
}) => {
  const role = useHookstate(globalRole);
  role.attach(Persistence('state.role'));

  const [timeLine, setTimeLine] = useState<TimeLine[]>();
  const [student, setStudent] = useState<StudentWithProfile>({
    ...emptyStudent,
    profile: emptyProfile,
    cohorts: [],
  });

  const fetchGradingTimeLine = async () => {
    if (!classSessionId) return;

    const studentId = String(userId);
    const { data: studentResult } = await a.get('/students/' + studentId);

    const { data: result } = await ClassService.fetchGradingTimeLine(
      classSessionId,
      studentId
    );

    if (!result) return;

    setStudent(studentResult);
    setTimeLine(
      result
        .flatMap((submission: Submission & { feedback: Feedback[] }) => [
          { createdAt: new Date(submission.submittedAt), type: 'SUBMISSION' },
          submission.scoredAt && {
            createdAt: new Date(submission.scoredAt),
            type: 'SCORE',
            content: submission.score.toString(),
          },
          ...submission.feedback.map((feedback) => ({
            content: feedback.content,
            createdAt: new Date(feedback.createdAt),
            type: 'FEEDBACK',
          })),
        ])
        .filter(Boolean)
        .sort((date1, date2) => date2.createdAt - date1.createdAt)
    );
  };

  React.useEffect(() => {
    fetchGradingTimeLine();
  }, [classId, userId, isUpdated, classSessionId]);

  return (
    <div className='divide-y -my-4'>
      {Array.isArray(timeLine)
        ? timeLine.map((v, i) => {
            return (
              <div key={i} className='flow-root p-2 py-4'>
                <div className='h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center float-left'>
                  {v.type === 'FEEDBACK' ? (
                    <ChatAltIcon
                      className=' h-5 w-5 text-green-500'
                      aria-hidden='true'
                    />
                  ) : v.type === 'SUBMISSION' ? (
                    <ClipboardIcon
                      className=' h-5 w-5 text-green-500'
                      aria-hidden='true'
                    />
                  ) : (
                    <ClipboardCheckIcon
                      className=' h-5 w-5 text-green-500'
                      aria-hidden='true'
                    />
                  )}
                </div>
                <div className='px-11'>
                  <p className='font-medium text-sm'>
                    {v.type === 'SUBMISSION'
                      ? role.value === 'student'
                        ? 'You'
                        : `${student.profile.firstName} ${
                            student.profile.lastName || ''
                          }`
                      : role.value === 'student'
                      ? 'Your teacher'
                      : 'You'}
                  </p>

                  <p className='font-medium text-sm text-gray-500 pb-0.5'>
                    {`${
                      v.type === 'SUBMISSION'
                        ? 'submitted'
                        : v.type === 'SCORE'
                        ? `graded ${v.content}%`
                        : 'commented'
                    } ${dayjs(v.createdAt).fromNow()}`}
                  </p>
                </div>
                <p className='pl-11'>
                  {v.type === 'FEEDBACK' && `${v.content}`}
                </p>
              </div>
            );
          })
        : null}
    </div>
  );
};

export default ScoringHistory;
